import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { GlobalService } from 'src/app/_services/global.service';
import { ApiService } from 'src/app/_services/api.service';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  title: string;
  form: FormGroup;

  constructor(private dialogRef: MatDialogRef<ResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any, private formBuilder: FormBuilder, public global: GlobalService, private api: ApiService) {
    this.title = this.data.title;
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      password: [null, Validators.required]
    });
  }


  onClickCancel(): void {
    this.dialogRef.close();
  }

  onClickSave() {
    if (this.form.invalid) {
      return;
    }
    this.global.showLoading('Generando nueva contraseña...');
    var data = this.form.value;

    switch (this.data.type) {
      case 'CUSTOMER':
        this.api.resetPasswordCustomer(data, this.data.customer.id).subscribe((data) => {
          this.global.hideLoading();
          if (this.global.is204(data)) {
            this.dialogRef.close(true);
            this.global.showMessage('Nueva clave generada con éxito');
          } else {
            this.global.showError(this.global.getMessage(data));
          }
        }, err => this.global.handleError(err));
        break;
      case 'ESTABLISHMENT':
        this.api.resetPasswordEstablishment(data, this.data.establishment.id).subscribe((data) => {
          this.global.hideLoading();
          if (this.global.is204(data)) {
            this.dialogRef.close(true);
            this.global.showMessage('Nueva clave generada con éxito');
          } else {
            this.global.showError(this.global.getMessage(data));
          }
        }, err => this.global.handleError(err));
        break;
      case 'USER':
        this.api.resetPasswordUser(data, this.data.user.id).subscribe((data) => {
          this.global.hideLoading();
          if (this.global.is204(data)) {
            this.dialogRef.close(true);
            this.global.showMessage('Nueva clave generada con éxito');
          } else {
            this.global.showError(this.global.getMessage(data));
          }
        }, err => this.global.handleError(err));
        break;
    }



  }


}
