<h1 mat-dialog-title>{{title}}</h1>
<div mat-dialog-content>
    <form autocomplete="off" novalidate [formGroup]="form">
        <div fxLayout="row wrap">
            <div fxFlex="100%">
                <mat-form-field appearance="outline">
                    <mat-label>Nueva contrase&ntilde;a</mat-label>
                    <input matInput autocomplete="off" formControlName="password" required>
                    <mat-error *ngIf="form.get('password').errors && form.get('password').errors.required ">
                        Este campo es obligatorio
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button mat-raised-button (click)="onClickCancel()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="onClickSave()" [disabled]="form.invalid">Guardar</button>
</div>