import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServersService {


  url: string;
  api: string;

  constructor(private http: HttpClient) {
    this.url = environment.protocol + environment.url + '/';
    this.api = environment.protocol + environment.url + '/api/';
  }

  getAll() {
    return this.http.get(this.api + 'speed-test/servers', { observe: 'response' });
  }
  add(data: any) {
    return this.http.post(this.api + 'speed-test/servers', data, { observe: 'response' });
  }
  update(id: number, data: any) {
    return this.http.put(this.api + 'speed-test/servers/' + id, data, { observe: 'response' });
  }
  delete(id: number) {
    return this.http.delete(this.api + 'speed-test/servers/' + id, { observe: 'response' });
  }

}
