import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ToolService {

  url: string;
  api: string;

  constructor(private http: HttpClient) {
    this.url = environment.protocol + environment.url + '/';
    this.api = environment.protocol + environment.url + '/api/';
  }

  getIP(): any {
    return this.http.get('https://api.ipify.org/?format=text', { responseType: 'text' });
  }

  getConfiguration(ip: string) {
    return this.http.get(this.api + 'speed-test/tool/configuration/' + ip, { observe: 'response' });
  }

  addHistoric(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historic', data, { observe: 'response' });
  }

  getHistorics(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/search', data, { observe: 'response' });
  }

  getHistoricsByUbigeo(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/ubigeo/search', data, { observe: 'response' });
  }

  getHistoricsDownload(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/download', data, { responseType: 'blob' });
  }

  getHistoricsByUbigeoDownload(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/ubigeo/download', data, { responseType: 'blob' });
  }

  getAverage(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/average', data, { observe: 'response' });
  }

  getAverageByUbigeo(data: any) {
    return this.http.post(this.api + 'speed-test/tool/historics/ubigeo/average', data, { observe: 'response' });
  }

}
