import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SpeedService {

  url: string;
  api: string;

  constructor(private http: HttpClient) {
    this.url = environment.protocol + environment.url + '/';
    this.api = environment.protocol + environment.url + '/api/';
  }

  getSpeeds() {
    return this.http.get(this.api + 'speeds', { observe: 'response' });
  }
  addSpeed(data: any) {
    return this.http.post(this.api + 'speeds', data, { observe: 'response' });
  }
  updateSpeed(id: number, data: any) {
    return this.http.put(this.api + 'speeds/' + id, data, { observe: 'response' });
  }
  deleteSpeed(id: number) {
    return this.http.delete(this.api + 'speeds/' + id, { observe: 'response' });
  }
}
